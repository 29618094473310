import Vue from 'vue';
import Axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';
let loginSign = true;
let tokenOff = true;
const querystring = require('qs');

console.log(process.env.VUE_APP_API_URL)
let config = {
  headers:{
    'Content-Type': 'application/x-www-form-urlencoded',
    "Cache-Control": "no-cache",
  },
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control

  // paramsSerializer: function(params) {
  //   // { arrayFormat: 'brackets' }
  //   return querystring.stringify(params)
  // },
  // transformRequest: [
  //   function(data) {
  //     // 对 data 进行任意转换处理
  //     // { arrayFormat: 'brackets' }
  //     return querystring.stringify(data)
  //   }
  // ],
};
const sevice = Axios.create(config);
// 请求拦截
sevice.interceptors.request.use(
  async (config) => {
    // console.log(config, 'config');
    let _token = JSON.parse(localStorage.getItem("token"));
    if (_token&&config.headers) {
      config.headers.Authorization = _token.value || "";
      // 刷新token
      if(_token&&_token.expiration&&tokenOff){
        const timeDiff = _token.expiration-new Date().getTime();
        if(timeDiff>0&&timeDiff<=10*60*1000){
          tokenOff = false;
          const res = await sevice({
            url:'/auth/refreshtoken',
            method: 'get'
          });
          if(res.code==200&&Object.keys(res.data).length){
            tokenOff = true;
            localStorage.setItem("token", JSON.stringify({ value: res.data.token, expiration: new Date().getTime() + 12 * 60 * 60 * 1000 }));
            config.headers.Authorization = res.data.token || "";
          }
        }
      }
    }
    return config
  },
  (error) => Promise.reject(error)
);
// 响应拦截
sevice.interceptors.response.use(
  (response) => {
    // console.error(response,'=== response ===',response.config);
    // Do something with response data
    if(response.config.responseType){
      if(response.status==200){
        return response
      }
    }
    const res = response.data || ''
    if (res.code == 200) {
      return res
    } else {
      console.error(`请求失败-->响应状态(${response.status}:${response.statusText})-->response.body`,res)
      let error = new Error((res.message||'请求失败')+`(${response.status}:${response.statusText})`);
      error.code = res.code;
      return Promise.reject(error)
    }
  },
  (error) => {
    // Do something with response error
    if(Axios.isCancel(error)){
      console.warn('Request canceled', error.message)
      return Promise.reject(new Error("请求已取消！"))
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.status);
      // console.log(error.response.headers);
      if(error.response.data && error.response.data.message){
        error.message = error.response.data.message
      }else{
        console.error(error.response);
      }
      error.code = error.response.status
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
      error.code = 0
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
      error.code = -1;
    }
    // console.log(error,'=1===1=1=1=1=');
    if(loginSign){
      let err = (error.response && error.response.data && error.response.data.message) || error.message;
      if ( error.code == 401 || err == "Request failed with status code 401" ) {
        err = "token到期，请重新登录";
        if(router.currentRoute.path!='/login'){
          router.push({
            path: '/login'
          })
        }
        loginSign = false;
        console.log(error, 'err-结束');
      }
      Message.error({
        message: err,
        type: "error",
        duration: 5 * 1000,
      });
    }
    
    // return Promise.reject(error)
    return Promise.resolve(error.response && error.response.data || error);
    // return error;
  }
);
Vue.use({
  install(Vue,options){
    Object.defineProperties(Vue.prototype, {
      $API_URL:{
        get(){
          return process.env.VUE_APP_API_URL;
        }
      },
    });
  }
})
export default sevice