<template>
  <div class="app-wrapper">
    <!-- header -->
    <HeaderBar />
    <!-- main -->
    <main class="main-page">
      <keep-alive :include="keepAlivePageName" :key="key">
        <router-view :key="key" />
      </keep-alive>
    </main>
    <!-- footer -->
    <FooterBar />
  </div>
</template>
<script>
import { getAuth } from '@/server/api/login'
export default {
  name: "Layout",
  props: {},
  components: {
    HeaderBar: () => import("@/components/layout/headerBar.vue"),
    FooterBar: () => import("@/components/layout/footerBar.vue")
  },
  data () {
    return {
    }
  },
  // 计算属性
  computed: {
    keepAlivePageName () {
      return this.$store.getters.keepAlivePageName
    },
    key () {
      return this.$route.path
    },
  },
  // 创建后
  created () {
    if (localStorage.getItem('token')) {
      this.getAuthInfo();
    }
  },
  //挂载后
  mounted () {
  },
  // 方法
  methods: {
    async getAuthInfo () {
      const res = await getAuth();
      console.log(res, '=== res auth ===');
      if (res.code == 200 && Object.keys(res.data).length) {
        const { user, menu, buttonArray } = res.data;
        localStorage.setItem('user', JSON.stringify(user));
        this.$store.dispatch("setUser", user);
        localStorage.setItem('menu', JSON.stringify(menu));
        this.$store.dispatch("setMenu", menu);
        localStorage.setItem('permission', JSON.stringify(buttonArray));
        this.$store.dispatch("setPerms", buttonArray);
      }
    },
  },
  // 组件销毁
  beforeDestroy () {
  },
  // 监听
  watch: {
  },
}
</script>
 
<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
}
.main-container {
}

.fixed-comment-inlet {
  position: fixed;
  right: 32px;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e9e9eb;
  border-radius: 8px;
  width: 50px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  li {
    color: #333;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 6px;
    cursor: pointer;
    overflow: hidden;
    &:nth-child(1) {
      background: #409eff;
      color: #fff;
      &:hover {
        background: #409eff;
        color: #fff;
      }
    }
    &:hover {
      background-color: #f4f4f5;
      color: #409eff;
    }
  }
}
</style>